import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import '../styles/Header.css';

const Header = ({ auth, setAuth }) => {
  const [projectsDropdownOpen, setProjectsDropdownOpen] = useState(false);
  const [managersDropdownOpen, setManagersDropdownOpen] = useState(false);
  const [experienceDropdownOpen, setExperienceDropdownOpen] = useState(false);
  const [passwordPrompt, setPasswordPrompt] = useState(false);
  const [enteredPassword, setEnteredPassword] = useState('');
  const [protectedRoute, setProtectedRoute] = useState('');
  const navigate = useNavigate();

  // Define passwords for protected routes
  const routePasswords = {
    '/payslipform': 'Gireesh@123',
    '/payment-installment': 'Gireesh@123',
    '/experience': 'Gireesh@123',
    '/admin': 'Gireesh@123'
  };

  const toggleDropdown = (setDropdown) => {
    setDropdown((prevState) => !prevState);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuth(null);
    navigate('/');
  };

  // Handle navigation to protected routes
  const handleProtectedNavigation = (event, path) => {
    event.preventDefault(); // Prevent default navigation

    // Always ask for password, even if previously verified
    setProtectedRoute(path);
    setPasswordPrompt(true);
  };

  // Verify the entered password
  const verifyPassword = () => {
    const correctPassword = routePasswords[protectedRoute];

    if (enteredPassword === correctPassword) {
      setPasswordPrompt(false);
      setEnteredPassword('');
      navigate(protectedRoute); // Navigate after verification
    } else {
      alert('Incorrect Password! Try Again.');
      setEnteredPassword('');
    }
  };

  // Render menu items
  const renderMenuItem = (label, path, requiresPassword = false) => (
    <Link
      to={path}
      onClick={(e) => (requiresPassword ? handleProtectedNavigation(e, path) : navigate(path))}
    >
      {label}
    </Link>
  );

  return (
    <header>
      <section className="image">
        <img src="/diamond.png" alt="logo" height={100} width={80} />
        <div className="user-role">
          {auth ? (auth === 'admin' ? 'Admin Login' : 'Guest Login') : 'Login'}
        </div>
      </section>
      <nav>
        <div className="login">
          {!auth ? (
            <Link to="/login">
              <FontAwesomeIcon icon={faUser} size="2x" />
            </Link>
          ) : (
            <button onClick={handleLogout}>
              <FontAwesomeIcon icon={faUser} size="2x" />
            </button>
          )}
        </div>

        {/* Always show Home and Contact */}
        <Link to="/">Home</Link>
        <Link to="/contact">Contact</Link>

        {/* Show additional links only if logged in */}
        {auth && (
          <>
            {/* Admin or Guest can see Projects and Documentation */}
            <div
              className="dropdown"
              onMouseEnter={() => toggleDropdown(setProjectsDropdownOpen)}
              onMouseLeave={() => toggleDropdown(setProjectsDropdownOpen)}
            >
              <span className="dropbtn">Projects</span>
              <div className={`dropdown-content ${projectsDropdownOpen ? 'show' : ''}`}>
                {renderMenuItem('Krishna Green Midlake III', '/projects/project1')}
                {renderMenuItem('Krishna Green North Star', '/projects/project2')}
              </div>
            </div>

            {/* Admin-only section */}
            {auth === 'admin' && (
              <div
                className="dropdown"
                onMouseEnter={() => toggleDropdown(setManagersDropdownOpen)}
                onMouseLeave={() => toggleDropdown(setManagersDropdownOpen)}
              >
                <span className="dropbtn">Option</span>
                <div className={`dropdown-content ${managersDropdownOpen ? 'show' : ''}`}>
                  {renderMenuItem('View/Download', '/download')}
                </div>
              </div>
            )}
            {/* Common sections for logged-in users */}
            {renderMenuItem('Documentation', '/document')}
            {renderMenuItem('PayslipForm', '/payslipform', true)}
            {renderMenuItem('Make Payment', '/payment-installment', true)}
            <div
              className="dropdown"
              onMouseEnter={() => toggleDropdown(setExperienceDropdownOpen)}
              onMouseLeave={() => toggleDropdown(setExperienceDropdownOpen)}
            >
              <span className="dropbtn">Experience</span>
              <div className={`dropdown-content ${experienceDropdownOpen ? 'show' : ''}`}>
              <div onClick={(e) => handleProtectedNavigation(e, '/admin')} className="dropdown-item">Admin
              </div>
              <div onClick={(e) => handleProtectedNavigation(e, '/experience')} className="dropdown-item"> Sales
              </div>
              </div>
            </div>
          </>
        )}
      </nav>

      {/* Password Prompt Modal */}
      {passwordPrompt && (
        <div className="password-modal">
          <div className="password-modal-content">
            <h3>Enter Password to Continue</h3>
            <input
              type="password"
              value={enteredPassword}
              onChange={(e) => setEnteredPassword(e.target.value)}
              placeholder="Enter Password"
            />
            <button onClick={verifyPassword}>Submit</button>
            <button onClick={() => setPasswordPrompt(false)}>Cancel</button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
